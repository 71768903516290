import React from 'react';

import Footer from '../components/Footer';

import { Container, Row, Col } from 'reactstrap';


import Projectblock from '../components/Projectblock';
import Layout from '../components/layout';
import SEO from '../components/seo';

import moment from 'moment';
import { graphql } from 'gatsby';
import ScrollingEff from '../components/ScrollingEff';
class Projects extends React.Component {


  render() {

    let title = "Projects";
    let content = "A chronological archive of what I am working on, or have been working on.";


    let edges = this.props.data.allMarkdownRemark.edges;

    let childNodes = edges.map(edge => {
       
      let headline = edge.node.frontmatter.title;
      let date = edge.node.frontmatter.date;
      let link = edge.node.frontmatter.path;
      let lead = edge.node.frontmatter.abstract;
      let image = edge.node.frontmatter.featureImage || "";
      
      let now = moment();
      if (now.isSameOrAfter(moment(date))) {
        return (
          <Projectblock
            headline={headline}
            date={date}
            link={link}
            lead={lead}
            image_src={image}
            image_alt={title}
            key={headline}
          />
        );
      } else {
        return null;
      }
        
      
    });



    return (
      <Layout>
      <SEO title="Home" />
      <ScrollingEff />
      <div>
        <Container fluid className="w-80 content-page block">
          
          <Row>
            <Col>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Col>
          </Row>
        </Container>
        {childNodes}

        <Footer />
      </div>
      </Layout>
      
      )
  }

}

export default Projects;

export const pageQuery = graphql`
    query {
allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {path: {regex: "/projects/"}, date: {}, lang: {}}}) {
  edges {
    node {
      id
      excerpt(pruneLength: 250)
      html
      frontmatter {
        date
        path
        featureImage
        title
        abstract
      }
    }
  }
}}`